// CustomNavbar.js
import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { PersonFill } from 'react-bootstrap-icons';
import './CustomNavbar.css'; // Ensure you import your custom CSS
import AuthenticationComponent from './Authentication'; // Import your authentication component

const CustomNavbar = ({ user, navExpanded, toggleNavbar, handleNavSelect, invoiceOrders, handleFetchOrderDetails }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [showAuth, setShowAuth] = useState(false);

    const handleAuthToggle = () => setShowAuth(!showAuth);

    useEffect(() => {
        const handleWindowResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return (
        <>
            <Navbar bg="light" expand="lg" expanded={navExpanded}>
                {isMobile ? (
                    <>
                        <Nav className="mr-auto">
                            <Nav.Link onClick={handleAuthToggle}>
                                <PersonFill color={user ? 'green' : 'red'} size={30} />
                            </Nav.Link>
                        </Nav>
                        <Navbar.Brand href="#home">QuickStop</Navbar.Brand>
                    </>
                ) : (
                    <Navbar.Brand href="#home">QuickStop</Navbar.Brand>
                )}
                <Navbar.Toggle onClick={toggleNavbar} aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto" onSelect={handleNavSelect}>
                        {user && (
                            <NavDropdown title="Orders" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#verify-order" eventKey='verify-order'>
                                    Verify Latest Order
                                </NavDropdown.Item>
                                {user.role === 'admin' && (
                                    <>
                                        <NavDropdown.Item href="#analyze-order" eventKey='analyze-order'>
                                            Analyze Latest Order
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href="#add-missing-items" eventKey='add-missing-items'>
                                            Add Latest Order Missing Items
                                        </NavDropdown.Item>
                                    </>
                                )}
                            </NavDropdown>
                        )}
                        {user && user.role === 'admin' && (
                            <>
                                <NavDropdown title="Admin" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="#config" eventKey='config'>
                                        Settings
                                    </NavDropdown.Item>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip>Download Latest Planogram PDFs from INS Analytics, and Generate a planogram database (effect seen in Planogram.Viewer)</Tooltip>}
                                    >
                                        <NavDropdown.Item href="#get-database" eventKey='get-database'>
                                            Planogram Database
                                        </NavDropdown.Item>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip>Download Latest Selling Price from Visualtouch, generate selling price database, also compare against previously downloaded selling price database and show the difference</Tooltip>}
                                    >
                                        <NavDropdown.Item href="#get-price-list" eventKey='get-price-list'>
                                            Selling Price List
                                        </NavDropdown.Item>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip>Download all the agile invoices by connecting to gmail services, generate the 'invoice cost price databse'</Tooltip>}
                                    >
                                        <NavDropdown.Item href="#get-updated-invoices" eventKey='get-updated-invoices'>
                                            Agile Invoices
                                        </NavDropdown.Item>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip>Use the planogram database to search for all items in Agile Showroom, generates the list of items found, not found, and also latest 'showroom cost price database'</Tooltip>}
                                    >
                                        <NavDropdown.Item href="#missing-items-agile" eventKey='missing-items-agile'>
                                            Missing Planogram Items in Agile
                                        </NavDropdown.Item>
                                    </OverlayTrigger>
                                </NavDropdown>
                                <NavDropdown title="Stats" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="#cost-price-db"
                                        eventKey='cost-price-db'>
                                        Cost Prices</NavDropdown.Item>
                                    <NavDropdown.Item href="#updated-selling-price-db"
                                        eventKey='updated-selling-price-db'>
                                        Last Updated Selling Prices</NavDropdown.Item>
                                    <NavDropdown.Item href="#selling-price-db"
                                        eventKey='selling-price-db'>
                                        Visualtouch Selling Prices</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Invoices" id="basic-nav-dropdown">
                                    {invoiceOrders.map((order, index) => (
                                        <NavDropdown.Item key={index} onClick={() => handleFetchOrderDetails(order)}>
                                            {order}
                                        </NavDropdown.Item>
                                    ))}
                                </NavDropdown>
                                
                            </>
                        )}
                        {user && (
                            <NavDropdown title="Planogram" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#viewer" eventKey='viewer'>
                                    Viewer
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#scanner" eventKey='scanner'>
                                    Scan Not Found Items
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}
                        {user && (
                            <NavDropdown title="Reports" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#instant_tickets" eventKey='instant_tickets'>
                                    Instant Tickets
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#end_of_shift" eventKey='end_of_shift'>
                                    End of Shift
                                </NavDropdown.Item>
                                {user.role === 'admin' && (
                                    <NavDropdown.Item href="#profit_calc" eventKey='profit_calc'>
                                        Profit Calculator
                                    </NavDropdown.Item>
                                )}
                            </NavDropdown>
                        )}
                    </Nav>
                </Navbar.Collapse>
                {!isMobile && (
                    <Nav className="ml-auto">
                        <Nav.Link onClick={handleAuthToggle}>
                            <PersonFill color={user ? 'green' : 'red'} size={30} />
                        </Nav.Link>
                    </Nav>
                )}
            </Navbar>
            <Modal show={showAuth} onHide={handleAuthToggle} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{user ? "Sign Out" : "Sign In / Sign Up"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AuthenticationComponent onHide={handleAuthToggle}/>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CustomNavbar;
