import React, { useState } from 'react';
import './App.css';
import Menu from './Menu';
// import AuthenticationComponent from './components/Authentication';
import UserContext from './UserContext';
import 'react-image-crop/dist/ReactCrop.css';


function App() {
  const [message, setMessage] = useState('');
  const [user, setUser] = useState(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
    <div className="App">
      {/* <AuthenticationComponent /> */}
      <Menu />
      <p>{message}</p>
    </div>
    </UserContext.Provider>
  );
}

export default App;
