export function createStyleRule({
    field,
    thresholds,
    colors = [
        { background: 'red', text: 'white' }, // Style for values < 0
        { background: 'orange', text: 'black' } // Style for values < 40
    ],
    textAlign = 'left' }) {
    return {
        field, // The field name to use for styling decisions
        styleFunction: (value) => { // The function to determine the style
            let style = { textAlign }; // Default text alignment

            // Determine the background color and text color based on thresholds
            if (value < thresholds[0]) {
                style.backgroundColor = colors[0].background;
                style.color = colors[0].text;
            } else if (value < thresholds[1]) {
                style.backgroundColor = colors[1].background;
                style.color = colors[1].text;
            } else {
                style.backgroundColor = 'transparent';
                style.color = 'black';
            }

            return style;
        }
    };
}


export function createCompareStyleRule({
    field1,
    field2,
    comparisonFunction,
    colors = [
        { background: 'red', text: 'white' }, // Style if the comparison function returns a negative result
        { background: 'green', text: 'white' }, // Style if the comparison function returns a positive result
        { background: 'white', text: 'black' } // Style if the comparison function returns equal or meets specific criteria
    ],
    textAlign = 'left'
}) {
    return {
        fields: [field1, field2], // Now using an array of fields
        styleFunction: (rowData) => { // Pass the entire row data
            const value1 = parseFloat(rowData[field1]);
            const value2 = parseFloat(rowData[field2]);
            let style = { textAlign }; // Default text alignment

            // Apply comparison function to determine the style
            const comparisonResult = comparisonFunction(value1, value2);
            if (comparisonResult < 0) {
                style.backgroundColor = colors[0].background;
                style.color = colors[0].text;
            } else if (comparisonResult > 0) {
                style.backgroundColor = colors[1].background;
                style.color = colors[1].text;
            } else {
                style.backgroundColor = colors[2].background;
                style.color = colors[2].text;
            }

            return style;
        }
    };
}

// Example comparison function
export function compareValues(value1, value2) {
    return value1 - value2; // Returns negative if value1 < value2, positive if value1 > value2, zero if equal
}
