import React, { useState, useEffect, useMemo } from 'react';
import { Collapse, Tabs, Tab, Button, Table } from 'react-bootstrap';
import PdfViewer from '../Pdf/PdfViewer'; // Update this path to your PdfViewer component
import DynamicTable from '../Common/DynamicTable'; // Update this path to your DynamicTable component
import axios from 'axios';
import { BACKEND_URL } from '../config'; // Update this path to your configuration file
import './PlanogramViewer.css'; // Import your custom styles
import ItemOverlay from './ItemOverlay';

const PlanogramViewer = ({ user, setMessage }) => {
    const [listItems, setListItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedTableItem, setSelectedTableItem] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [planogramListOpen, setPlanogramListOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [overlayOpen, setOverlayOpen] = useState(false);
    const [currentOverlayIndex, setCurrentOverlayIndex] = useState(0);
    const [showSerialNo, setShowSerialNo] = useState(false);

    const showDetails = () => {
        setShowSerialNo(!showSerialNo);
    }
    
    useEffect(() => {
        axios.get(`${BACKEND_URL}/run-get_planogram_database/`)
            .then(response => {
                // Assuming response.data is the array of arrays from your JSON
                const firstData = response.data.planogram_database;
                const secondData = response.data.selling_price_database;
                const thirdData = response.data.cost_price_agile_database;
                // Process and merge data
                const mergedData = firstData.map(firstRow => {
                    const itemNumber = firstRow[2]; // Assuming item number is at index 2
                    const upcNumber = firstRow[1]; // Assuming upc number is at index 1
                    const secondRow = secondData.find(row => (
                        row[0] === itemNumber ||
                        itemNumber.replace(/^T/, '').replace(/^0+/, '') === row[0].replace(/^0+/, '')
                    ));
                    const sellingPrice = secondRow ? secondRow[secondRow.length - 1] : "NOT_FOUND";
                    const thirdRow = thirdData.find(row => row[1] === upcNumber);
                    const costPrice = thirdRow ? thirdRow[thirdRow.length - 1] : "NOT_FOUND";
                    let markupValue = "--"
                    if (sellingPrice !== "NOT_FOUND" && costPrice !== "NOT_FOUND") {
                        markupValue = Math.round((parseFloat(sellingPrice) - parseFloat(costPrice)) / parseFloat(costPrice) * 100 * 100) / 100;
                    }
                    return {
                        rowData_hidden: firstRow.slice(0),
                        item_image: `${BACKEND_URL}/static/downloaded_images/${upcNumber}.jpeg`,
                        item_image_modified_hidden: false,
                        description_combined_middle: firstRow[3],
                        quantity_editable: "0",
                        costPrice_num_admin: costPrice,
                        sellingPrice_num: sellingPrice,
                        markup_num_admin: markupValue,
                        upc_combined_bottom: upcNumber,
                        itemNumber_combined_top: itemNumber,
                        expiryDate: "01/01/2023"
                    };
                });
                console.log(response.data.planogram_database);  // Log to inspect the structure

                // Extracting list items (file names) and sorting them
                const fileList = Array.from(new Set(response.data.planogram_database.map(item => item[0])))
                    .sort();  // Sort the list alphabetically
                fileList.unshift('SHOW ALL');  // Add 'SHOW ALL' at the start of the list

                // Setting state
                setListItems(fileList);
                setTableData(mergedData);
            })
            .catch(error => {
                console.error('Error fetching planogram data:', error);
            });
    }, []);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredTableData = useMemo(() => {
        let displayData = tableData.filter(row =>
            row.description_combined_middle.toLowerCase().includes(searchTerm)
        );
        if (!selectedItem) {
            return [];
        } else if (selectedItem === 'SHOW ALL') {
            return displayData;  // Show all items
        } else {
            displayData = displayData.filter(row => row.rowData_hidden[0] === selectedItem);
        }
        return displayData;
    }, [tableData, searchTerm, selectedItem]);
    
    const handleListItemClick = (itemName) => {
        setSelectedItem(itemName);
        setPlanogramListOpen(false); // Automatically close the list when an item is clicked
    };

    const handlePlanogramListToggle = () => {
        setPlanogramListOpen(!planogramListOpen);
    };

    const handleQuantityChange = (updatedRowData) => {
        // itemNumber, newQuantity
        setTableData(prevData => prevData.map((item) =>
            item.itemNumber_combined_top === updatedRowData.itemNumber_combined_top ? { ...item, quantity_editable: updatedRowData.quantity_editable } : item
        ));
    };
    const handleSaveEntry = (updatedRowData) => {
        // itemNumber, newQuantity
        setTableData(prevData => prevData.map((item) =>
            item.itemNumber_combined_top === updatedRowData.itemNumber_combined_top ? { ...item, quantity_editable: updatedRowData.quantity_editable,
                expiryDate: updatedRowData.expiryDate,
                item_image_modified_hidden: updatedRowData.item_image_modified_hidden,
                item_image: updatedRowData.item_image } : item
        ));
    };

    const handleSelectRow = (item) => {
        setOverlayOpen(true);
        setSelectedTableItem(item);
    }

    const handleSaveButtonClick = () => {
        const items = tableData.map((item, index) => ({
            upc: item.rowData_hidden[1],
            description: item.description_combined_middle,
            quantity: item.quantity_editable,
            selling_price: item.sellingPrice_num,
            expiry_date: item.expiryDate,
            image: item.item_image_modified_hidden ? item.item_image : null
        }));
    
        const jsonData = {
            userEmail: user.email,
            items: items
        };
    
        axios.post(`${BACKEND_URL}/run-save_quantity_data/`, jsonData, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            console.log('Data saved successfully:', response.data);
            setMessage("Data saved successfully.")
        })
        .catch(error => {
            console.error('Error saving data:', error);
            setMessage("Error saving data.")
        });
    };
    
    

    const handleLoadQuantities = () => {
        axios.get(`${BACKEND_URL}/run-get_quantity_database/`)
            .then(response => {
                // Assuming the response data is an array of objects with itemNumber and quantity
                const quantityData = response.data.quantity_database; // Adjust this based on your actual response structure

                // Create a mapping from upc to quantity for easier lookup
                const quantityMap = new Map(quantityData.map(item => [item.upc, item.quantity]));
                const expiryMap = new Map(quantityData.map(item => [item.upc, item.expiry_date]));

                // Update tableData with the quantities from the response
                const updatedTableData = tableData.map(item => {
                    // If the upc exists in the quantityMap, update the quantity_editable
                    if (quantityMap.has(item.upc_combined_bottom)) {
                        return { ...item, quantity_editable: quantityMap.get(item.upc_combined_bottom), expiryDate: expiryMap.get(item.upc_combined_bottom)};
                    }
                    // If not found, return the item unchanged
                    return item;
                });

                // Update the state with the new table data
                setTableData(updatedTableData);
                setMessage(response.data.message);
            })
            .catch(error => {
                console.error('Error fetching quantity data:', error);
            });
    };
    const handleNavigate = (event, direction) => {
        event.preventDefault();
        event.stopPropagation();
        console.log(`Navigating, direction: ${direction}`); // Check if this logs correctly
      
        setCurrentOverlayIndex(prev => {
          const newIndex = prev + direction;
          console.log(`New index: ${newIndex}`); // Log the new index to ensure it's calculated correctly
          return newIndex >= 0 && newIndex < filteredTableData.length ? newIndex : prev;
        });
      };
      

    return (
        <div>
            <div className="search-section">
                <input
                    type="text"
                    placeholder="Search in Description..."
                    onChange={handleSearchChange}
                    value={searchTerm}
                />
            </div>
            <div className="viewer-layout">
                <Button
                    onClick={handlePlanogramListToggle}
                    className={`toggle-list-button ${planogramListOpen ? 'open' : ''}`}
                    aria-controls="collapsible-list"
                    aria-expanded={planogramListOpen}
                >
                    {planogramListOpen ? '←' : '→'} {/* Change these arrows as needed */}
                </Button>

                <Collapse in={planogramListOpen}>
                    <div id="collapsible-list">
                        <div className='list-section'>
                            {listItems.map((item, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleListItemClick(item)}
                                    className={`list-item ${item === selectedItem ? 'selected' : ''}`}
                                >
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                </Collapse>
                {filteredTableData.length > 0 ? ( // Check if there is data to display
                    <Table striped bordered hover>
                        <Tabs defaultActiveKey="tableData" id="planogram-tabs">
                            <Tab eventKey="pdfViewer" title="View PDF">
                                {selectedItem && <PdfViewer file={`${BACKEND_URL}/run-view_pdf/${selectedItem}`} />}
                            </Tab>
                            <Tab eventKey="tableData" title="Data Table">
                                <Button onClick={showDetails} className="mt-3">
                                    Show Details
                                </Button>
                                <Button onClick={handleSaveButtonClick} className="mt-3">
                                    Save
                                </Button>
                                <Button onClick={handleLoadQuantities} className="mt-3">
                                    Load Quantity
                                </Button>
                                <Button onClick={() => { setOverlayOpen(true); setSelectedTableItem(null); setCurrentOverlayIndex(0); }} className="mt-3">
                                    View Items
                                </Button>
                                <ItemOverlay
                                show={overlayOpen}
                                onHide={() => setOverlayOpen(false)}
                                data={filteredTableData}
                                index={currentOverlayIndex}
                                item={selectedTableItem} // Pass the selected item directly
                                onNavigate={handleNavigate}
                                onSave={handleSaveEntry}
                                />
                                <DynamicTable
                                    data={filteredTableData}
                                    showSerialNo={showSerialNo}
                                    onSaveRow={handleQuantityChange}
                                    user={user}
                                    onRowSelect={handleSelectRow}
                                />
                            </Tab>
                        </Tabs>
                    </Table>
                    ) : (
                        <div class="parent-container">
                            <div className="placeholder-message">
                                Select a planogram section using the blue floating icon at the top left.
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
};

export default PlanogramViewer;
