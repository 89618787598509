import React, { useState, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './CustomGrid.css'; // Import your custom styles
import ImageCellRenderer from './ImageCellRenderer'; // Adjust the path as necessary
import DesciptionCellRenderer from './DescriptionCellRenderer';

const CustomCellRenderer = ({ data, context }) => {
  return <button onClick={() => context.onDelete(data.upc)}>Delete</button>;
};

const CustomHeaderComponent = (props) => {
  const { displayName, context } = props;
  const { enableToggle, toggleSNo, isToggled } = context;

  return (
    <div>
      <span>S.No.</span>
    </div>
  );
};

function DynamicTable({ 
  data, 
  onSaveRow, 
  showSerialNo, 
  user, 
  allowDeleteRow, 
  onDeleteRow, 
  allCapsColumns, 
  columnStyleRules, 
  universalStyleRule, 
  onRowSelect, 
  combinedColumnIndex = 3, // default to 3 if not provided
  sumColumns = [] // Add the sumColumns prop with a default empty array
}) {
  const [gridApi, setGridApi] = useState(null);
  const [toggleSNo, setToggleSNo] = useState(true); // State to control S.No. toggle

  // Toggle function for S.No.
  const toggleSerialNo = () => setToggleSNo(!toggleSNo);

  const isColumnVisible = (columnName) => {
    if (!user || (user && user.role === 'admin')) {
      return true;
    } else {
      return !columnName.endsWith("_admin");
    }
  };

  const saveUpdatedRow = (event) => {
    if (onSaveRow) {
      onSaveRow(event.data);
    } else {
      console.log('Updated row:', event.data);
    }
  };

  const deleteRow = (data) => {
    if (onDeleteRow) {
      onDeleteRow(data);
    } else {
      console.log('Delete row:', data);
    }
  };

  const isNumericColumn = (key) => {
    return key.includes("_num");
  };

  const makeUpperCase = (params) => {
    return params.value ? params.value.toUpperCase() : '';
  };

  const handleRowClick = (event) => {
    if (onRowSelect) {
      onRowSelect(event.data);
    } else {
      console.log('Row clicked:', event.data);
    }
  };
  const calculateSums = (data, sumColumns) => {
    if (!Array.isArray(data) || data.length === 0) return {};
    
    const sums = {};
    sumColumns.forEach(key => {
      if (data[0].hasOwnProperty(key)) {
        sums[key] = data
          .reduce((acc, row) => acc + (Number(row[key]) || 0), 0)
          .toFixed(2); // Round to two decimal places
      }
    });
    
    return sums;
  };
  

  const [gridData, setGridData] = useState(data);

  useEffect(() => {
    if (sumColumns.length > 0) {
      const updatedData = [...data, { ...calculateSums(data, sumColumns), isSumRow: true }];
      setGridData(updatedData);
    } else {
      setGridData(data);
    }
  }, [data, sumColumns]);

  const columnDefs = useMemo(() => {
    let cols = [];
    if (showSerialNo) {
      cols.push({
        headerName: "S.No.",
        valueGetter: "node.rowIndex + 1",
        maxWidth: 90,
        sortable: true,
        headerComponent: CustomHeaderComponent,
        headerComponentParams: {
          displayName: "S.No.",
          enableToggle: true,
          toggleSNo: toggleSerialNo,
          isToggled: toggleSNo
        },
      });
    }

    let combined_keys = [];

    if (Array.isArray(data) && data.length > 0) {
      cols = cols.concat(Object.keys(data[0]).map(key => {
        const isEditable = key.endsWith("_editable") || (key.endsWith("_admin") && key.includes("_editable"));
        const isNumeric = isNumericColumn(key);
        const isHidden = key.endsWith("_hidden") || !isColumnVisible(key);
        const isImage = key.endsWith("_image");
        const isCombinedKey = key.includes("_combined");
        if (isCombinedKey) {
          combined_keys.push(key);
          return null;
        }

        let displayName = key
          .replace(/_num_/g, '_')
          .replace(/_combined_/g, '_')
          .replace(/_top$/g, '')
          .replace(/_bottom$/g, '')
          .replace(/_middle$/g, '')
          .replace(/_num$/, '')
          .replace(/_image$/, '')
          .replace(/_editable$/, '')
          .replace(/_editable_admin/, '')
          .replace(/_admin$/, '')
          .replace(/_hidden$/, '') // Remove '_hidden' from the display name if present
          .split('_') // Split by underscore
          .map(part => part.charAt(0).toUpperCase() + part.slice(1)) // Capitalize first letter of each part
          .join(' '); // Join them back with a space

        let colDef = {
          headerName: displayName,
          field: key,
          sortable: true,
          filter: true,
          editable: (params) => isEditable && !params.data?.isSumRow, // Correct usage of params
          hide: isHidden,
          headerClass: 'header-left-align',
          cellStyle: (params) => {
            if (params.data?.isSumRow) {
              return { fontWeight: 'bold', backgroundColor: '#f0f0f0' };
            }
            if (universalStyleRule && universalStyleRule.field && universalStyleRule.styleFunction) {
              return universalStyleRule.styleFunction(params.data[universalStyleRule.field], params.data);
            }
            if (universalStyleRule && universalStyleRule.fields && universalStyleRule.styleFunction) {
              return universalStyleRule.styleFunction(params.data);
            }
            return null;
          },
          cellRenderer: (params) => {
            if (allCapsColumns && allCapsColumns.includes(key) && params.value) {
              return params.value.toUpperCase();
            }
            return params.value;
          }
        };
        if (isNumeric) {
          colDef.type = 'numberColumn';
          colDef.valueGetter = function(params) {
            if (params.data[key] === "NOT_FOUND") {
              return params.data[key];
            }
            return Number(params.data[key]);
          };
        }

        if (allCapsColumns && allCapsColumns.includes(key)) {
          colDef.onCellValueChanged = (params) => {
            params.data[key] = params.newValue.toUpperCase();
            saveUpdatedRow(params);
          };
        }
        if (isImage) {
          colDef.cellRenderer = ImageCellRenderer;
        }

        return colDef;
      })).filter(col => col !== null);
    }

    if (combined_keys.length !== 0) {
      const combinedColumn = {
        headerName: "Description",
        field: "description",
        cellRenderer: DesciptionCellRenderer,
        cellRendererParams: {
          combinedKeys: combined_keys,
          dataFunction: (data) => {
            return combined_keys.reduce((acc, key) => {
              acc[key] = data[key];
              return acc;
            }, {});
          }
        },
        sortable: true,
        filter: true,
        autoHeight: true
      };
      cols.splice(combinedColumnIndex, 0, combinedColumn);
    }

    if (allowDeleteRow) {
      cols.push({
        headerName: 'Delete',
        field: "delete",
        maxWidth: 90,
        cellRenderer: CustomCellRenderer,
      });
    }

    return cols;
  }, [data, allCapsColumns, universalStyleRule, toggleSerialNo, showSerialNo, toggleSNo]);

  const myFrameworkComponents = {
    customCellRenderer: CustomCellRenderer,
    customHeaderComponent: CustomHeaderComponent,
    imageCellRenderer: ImageCellRenderer,
    desciptionCellRenderer: DesciptionCellRenderer,
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
    cellStyle: { textAlign: 'left' },
  }), []);

  useEffect(() => {
    if (gridApi && data.length) {
      const timeoutId = setTimeout(() => {
        gridApi.autoSizeAllColumns();
      }, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [data, gridApi]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.autoSizeAllColumns();
  };

  const onFirstDataRendered = (params) => {
    params.api.autoSizeAllColumns();
  };

  const gridOptions = useMemo(() => ({
    columnTypes: {
      numberColumn: {
        filter: 'agNumberColumnFilter',
      },
    },
    suppressMovableColumns: true,
    getRowStyle: () => ({ 'userSelect': 'text' }),
    multiSortKey: "ctrl",
  }), []);

  return (
    <div className="ag-theme-alpine custom-grid-class" style={{ height: 'auto', width: '100%', overflowX: 'auto' }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={gridData}
        onGridReady={onGridReady}
        domLayout='autoHeight'
        onCellEditingStopped={saveUpdatedRow}
        onFirstDataRendered={onFirstDataRendered}
        frameworkComponents={myFrameworkComponents}
        context={{ onDelete: deleteRow, toggleSNo: toggleSerialNo, isToggled: toggleSNo }}
        gridOptions={gridOptions}
        defaultColDef={defaultColDef}
        onRowClicked={handleRowClick}
      />
    </div>
  );
}

export default DynamicTable;
