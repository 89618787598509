import React, { useState, useEffect } from 'react';
import { Form, InputGroup, ButtonGroup, Button, Dropdown } from 'react-bootstrap';
import DynamicTable from '../Common/DynamicTable';
import { BACKEND_URL } from '../config';
import axios from 'axios';
import ProfitItemOverlay from './ProfitItemOverlay';

const ProfitCalcComponent = ({ isUnArchived, date_from, date_to,
                                handleCheckboxChange, handleProfitDateChange,
                                handleGetProfitCalc, profitData, profitItemTransactions, profitHighlightRule }) => {
    const [displayedData, setDisplayedData] = useState(profitData);
    const [activeFilter, setActiveFilter] = useState('all');  // Track active filter
    const [showOverlay, setShowOverlay] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    
    const setSelectedItemAndShowOverlay = (item) => {
        setSelectedItem(item);
        setShowOverlay(true);
    };
    
    // Add logic to close the overlay
    const closeOverlay = () => {
        setShowOverlay(false);
        setSelectedItem(null);
    };
    
    // Ensure onSaveProfitData is defined
    const onSaveProfitData = (updatedRowData) => {
        axios.post(`${BACKEND_URL}/run-save_profit_data/`, updatedRowData)
        .then(response => {
            console.log('Row saved successfully:', response.data);
        })
        .catch(error => {
            console.error('Error saving row:', error);
        });
    };

    useEffect(() => {
        setDisplayedData(profitData);
    }, [profitData]);

    const filterData = (filterType) => {
        setActiveFilter(filterType);  // Update active filter state
        switch (filterType) {
            case 'negatives':
                setDisplayedData(profitData.filter(item => (item.qtySold_num > 0) && (item.profit_num < 0) && item.profit_num !== "NOT_FOUND"));
                break;
            case 'missing':
                setDisplayedData(profitData.filter(item => (item.qtySold_num > 0) && (/not|nan/i.test(String(item.profitPercent_num)))));
                break;
            default:
                setDisplayedData(profitData);
        }
    };

    return (
        <div>
            <Form.Group controlId="formBasicCheckbox">
                <Form.Check 
                    type="checkbox" 
                    label="Today's Open Sales" 
                    checked={isUnArchived} 
                    onChange={handleCheckboxChange} 
                />
            </Form.Group>
            <InputGroup className="mb-3">
                <Form.Control 
                    type="date" 
                    value={date_from} 
                    onChange={handleProfitDateChange('startDate')} 
                    disabled={isUnArchived} 
                />
                <Form.Control 
                    type="date" 
                    value={date_to} 
                    onChange={handleProfitDateChange('endDate')} 
                    disabled={isUnArchived} 
                />
            </InputGroup>
            <Button variant="dark" onClick={handleGetProfitCalc}>Refresh</Button>
            <Dropdown as={ButtonGroup}>
                <Button variant="dark">Filter</Button>
                <Dropdown.Toggle split variant="dark" id="dropdown-custom-2" />
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => filterData('all')} active={activeFilter === 'all'}>Show All</Dropdown.Item>
                    <Dropdown.Item onClick={() => filterData('negatives')} active={activeFilter === 'negatives'}>Show Negatives</Dropdown.Item>
                    <Dropdown.Item onClick={() => filterData('missing')} active={activeFilter === 'missing'}>Show Missing Cost Price</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <DynamicTable data={displayedData} universalStyleRule={profitHighlightRule} onSaveRow={onSaveProfitData} onRowSelect={setSelectedItemAndShowOverlay}/>
            {showOverlay && <ProfitItemOverlay item={selectedItem} detailData={profitItemTransactions[selectedItem.itemNumber]} onClose={closeOverlay} />}
        </div>
    );
};

export default ProfitCalcComponent;
