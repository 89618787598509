import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import 'pdfjs-dist/web/pdf_viewer.css'; // Ensure you have styles for pdf.js if you are using it

const PdfViewer = ({ file }) => {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div>
            <div onClick={handleOpenModal} style={{ cursor: 'pointer', width: '100%', height: '100vh' }}>
                {/* Implement your PDF viewer here, making sure it fits the container */}
                <iframe src={file} style={{ width: '100vh', height: '100%' }} title="PDF Viewer"></iframe>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                <Modal.Body>
                    <iframe src={file} style={{ width: '100%', height: '100vh' }} title="Full Size PDF"></iframe>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default PdfViewer;
