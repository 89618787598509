import React from 'react';

const DescriptionCellRenderer = (params) => {
  // Retrieve the combined keys and data function from params
  const { combinedKeys, dataFunction } = params;
  const combinedData = dataFunction(params.data);

  const containerStyle = {
    padding: '2px 0',
    margin: '0', // Ensures no external margin
    lineHeight: '1.2', // Reduces the line height to reduce space between lines
  };

  const itemStyle = {
    fontWeight: 'bold',
    margin: '2px 0',
    padding: '0', // Ensure no padding
    lineHeight: '1.2', // Compact line height
  };

  const keyValueStyle = {
    color: 'grey',
    fontSize: 'smaller',
    margin: '2px 0',
    padding: '0',
    lineHeight: '1.2',
  };

  return (
    <div style={containerStyle}>
      {combinedKeys.map(key => {
        // Check if the key contains 'description'
        if (key.toLowerCase().includes('description')) {
          return (
            <div key={key} style={itemStyle}>
              {combinedData[key]}  {/* Display only the value */}
            </div>
          );
        } else {
          let keyDisplayName = key.replace(/_combined_/g, '_')
            .replace(/_top$/g, '')
            .replace(/_bottom$/g, '');
          return (
            <div key={keyDisplayName} style={keyValueStyle}>
              <strong>{keyDisplayName}:</strong> {combinedData[key]}  {/* Display both key and value */}
            </div>
          );
        }
      })}
    </div>
  );
};

export default DescriptionCellRenderer;
