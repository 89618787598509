import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import './ProfitItemOverlay.css'; // Import your custom styles

const ProfitItemOverlay = ({ item, detailData, onClose }) => {
    if (!item) return null;

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Item Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Description: {item.description}</p>
                <p>Item Number: {item.itemNumber}</p>
                <h3>Transactions:</h3>
                {detailData && detailData.length > 0 ? (
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Check No.</th>
                                <th>Selling Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {detailData.map((transaction, index) => (
                                <tr key={index}>
                                    <td>{transaction.date}</td>
                                    <td>{transaction.time}</td>
                                    <td>{transaction.check_number}</td>
                                    <td>${transaction.selling_price}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : <p>No transaction details available.</p>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProfitItemOverlay;
