import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FaCamera, FaPlus, FaTrash } from 'react-icons/fa';
import "react-datepicker/dist/react-datepicker.css";
import './ItemOverlay.css';

const ItemOverlay = ({ show, onHide, data, item, index, onNavigate, onSave }) => {
    const currentItem = item || (data && data[index]) || null;
    const initialEntries = currentItem && Array.isArray(currentItem.entries) ? currentItem.entries : [{
        quantity: 0,
        expiryDate: new Date(),
        category: 'Default'
    }];
    const [entries, setEntries] = useState(initialEntries);
    const [imageFile, setImageFile] = useState(null);
    const [imageFileModified, setImageFileModified] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const modalElement = document.querySelector('.modal-dialog');
            if (modalElement) {
                modalElement.style.height = `${window.innerHeight}px`;
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // This useEffect will trigger autoSave whenever entries or imageFile changes.
        autoSave();
    }, [entries, imageFile]); // Added imageFile to dependencies to also save when image changes


    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const reader = new FileReader();
    
            reader.onload = (e) => {
                const imgElement = document.createElement("img");
                imgElement.src = e.target.result;
    
                imgElement.onload = () => {
                    const canvas = document.createElement("canvas");
                    const scaleFactor = 0.5; // Reduces both width and height by 50%
    
                    canvas.width = imgElement.width * scaleFactor;
                    canvas.height = imgElement.height * scaleFactor;
    
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(imgElement, 0, 0, canvas.width, canvas.height);
    
                    const newDataUrl = canvas.toDataURL("image/jpeg", 0.7); // Compress to 70% quality
    
                    setImageFile(newDataUrl); // Now this is a smaller Base64 string
                    setImageFileModified(true);
                };
            };
    
            reader.readAsDataURL(file);
        }
    };
    
    const autoSave = () => {
        const totalQuantity = entries.reduce((sum, entry) => sum + Number(entry.quantity), 0);
        console.log('Saving data:', { entries, totalQuantity, imageFile});
        const updatedData = {
            ...currentItem,
            quantity_editable: totalQuantity,
            entries,
            item_image: imageFile,
            item_image_modified_hidden: imageFileModified
        };
        onSave(updatedData);
    };

    const updateEntry = (index, field, value) => {
        const newEntries = entries.slice();
        newEntries[index] = { ...newEntries[index], [field]: value };
        setEntries(newEntries); // Auto-save after updating entries
    };

    const handleAddEntry = () => {
        const newEntry = {
            quantity: 0,
            expiryDate: new Date(),
            category: 'New Category'
        };
        setEntries(prev => [...prev, newEntry]); // Auto-save after adding an entry
    };

    const handleDeleteEntry = (index) => {
        const updatedEntries = entries.filter((_, idx) => idx !== index);
        setEntries(updatedEntries); // Auto-save after deleting an entry
    };

    if (!currentItem && !entries.length) {
        return null;
    }

    return (
        <Modal show={show} onHide={onHide} size="lg" centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{currentItem.description_combined_middle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="image-container" style={{ position: 'relative' }}>
                    <img src={imageFile || (currentItem && currentItem.item_image)} alt={currentItem ? currentItem.description_combined_middle : 'No Image'} style={{ width: '100%', height: '300px', objectFit: 'contain', display: 'block' }} />
                    <input type="file" accept="image/*" style={{ display: 'none' }} id="file-input" onChange={handleImageChange} />
                    <Button variant="light" className="camera-button" onClick={() => document.getElementById('file-input').click()}><FaCamera /></Button>
                    <Button variant="light" className="navigate-button left" onClick={(e) => onNavigate(e, -1)} disabled={index <= 0}>←</Button>
                    <Button variant="light" className="navigate-button right" onClick={(e) => onNavigate(e, 1)} disabled={(data.length > 1) && (index >= data.length - 1)}>→</Button>
                </div>
                {entries.map((entry, idx) => (
                    <Form key={idx}>
                        <Row className="mb-3">
                            <Col xs={12} md={4}>
                                <Form.Group as={Col}>
                                    <Form.Label>Category</Form.Label>
                                    <Form.Control as="select" value={entry.category} onChange={(e) => updateEntry(idx, 'category', e.target.value)}>
                                        <option value="Default">Default</option>
                                        <option value="New Category">New Category</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={6} md={3}>
                                <Form.Group as={Col}>
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control type="number" value={entry.quantity} onChange={(e) => updateEntry(idx, 'quantity', e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col xs={6} md={3}>
                                <Form.Group as={Col}>
                                    <Form.Label>Expiry Date</Form.Label>
                                    <DatePicker selected={new Date(entry.expiryDate)} onChange={(date) => updateEntry(idx, 'expiryDate', date)} className="form-control" />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={1}>
                                <Button variant="danger" className="mt-4" onClick={() => handleDeleteEntry(idx)}><FaTrash /></Button>
                            </Col>
                        </Row>
                    </Form>
                ))}
                <Button variant="primary" onClick={handleAddEntry} disabled={true}><FaPlus /> Add Entry</Button>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ItemOverlay;
