import React, { useState } from 'react';
import ReactDOM from 'react-dom';

const ImageCellRenderer = ({ value }) => {
    const [showModal, setShowModal] = useState(false);

    const handleClick = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const modalContent = showModal ? (
        ReactDOM.createPortal(
            <div
                className="modal-overlay"
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1050,
                }}
                onClick={handleClose}
            >
                <div
                    className="modal-content"
                    style={{
                        position: 'relative',
                        padding: '20px',
                        backgroundColor: '#fff',
                        borderRadius: '10px',
                        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                        maxWidth: '80%',
                        maxHeight: '80%',
                        cursor: 'default',
                    }}
                    onClick={e => e.stopPropagation()}
                >
                    <span
                        className="close"
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            cursor: 'pointer',
                            fontSize: '24px',
                            fontWeight: 'bold',
                        }}
                        onClick={handleClose}
                    >
                        &times;
                    </span>
                    <img
                        src={value}
                        alt="Full Size Image"
                        style={{
                            maxHeight: '100%',
                            maxWidth: '100%',
                            display: 'block',
                        }}
                    />
                </div>
            </div>,
            document.body
        )
    ) : null;

    return (
        <>
            {value && (
                <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img
                        src={value}
                        alt="Thumbnail"
                        style={{
                            maxHeight: '100%',
                            maxWidth: '100%',
                            height: '100%',
                            aspectRatio: '1 / 1' // Ensures the width is the same as the height
                        }}
                        onClick={handleClick}
                    />
                </div>
            )}
            {modalContent}
        </>
    );
};

export default ImageCellRenderer;
